.projectCardImage {
  border-radius: 8px;
  object-fit: cover;
}

.projectCard:hover > img {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
  cursor: pointer;
  -moz-transition: 0.2s !important;
  -webkit-transition: 0.2s !important;
  -o-transition: 0.2s !important;
}
