.MuiChip-sizeSmall {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50% !important;
  margin: 3px;
}

.MuiCard-root > img {
  padding: 9px !important;
  border-radius: 18px !important;
  width: 80px;
}

.MuiCard-root #inform-card {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid !important;
  height: 100%;
  /* cursor: pointer; */
  padding: 0.9rem;
}

.clickedTop {
  border-radius: 8px !important;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 16px !important;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: height 0.2s !important;
  -webkit-transition: height 0.2s !important;
  -o-transition: height 0.2s !important;
}

.MuiCard-root .MuiCardMedia-img {
  width: 80px;
  -moz-transition: width 0.2s !important;
  -webkit-transition: width 0.2s !important;
  -o-transition: width 0.2s !important;
}

.MuiCard-root .MuiCardMedia-img:hover {
  /* box-shadow: rgb(0 0 0 / 15%) 0px 4px 16px !important;  */
  cursor: pointer;
  /* width: 300px;
  -moz-transition: width 0.2s !important;
  -webkit-transition: width 0.2s !important;
  -o-transition: width 0.2s !important;*/
}

#top:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 16px !important;
  -moz-transition: 0.2s !important;
  -webkit-transition: 0.2s !important;
  -o-transition: 0.2s !important;
}

.imageClicked {
  width: 100% !important;
  height: 100% !important;
  -moz-transition: width 0.2s !important;
  -webkit-transition: width 0.2s !important;
  -o-transition: width 0.2s !important;
}

.MuiCard-root {
  opacity: 1;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;

  height: 80px;
  -moz-transition: height 0.4s !important;
  -webkit-transition: height 0.45s !important;
  -o-transition: height 0.4s !important;
}

.MuiButton-root {
  padding: 0px;
}

.dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
