/* Ensure that the first div has a 100% height for the ARC container to work properly */
body,
html,
#root {
  height: 100%;
  margin: 0;

  /* line-height: var(--arc-line-height-dense); */
  --arc-font-body: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  --theme-primary-color: 'rgb(var(--arc-red-060))';
}

arc-navbar {
  --logo-height: 1.7rem;
  --arc-box-shadow: none;
  --arc-border-width: 0px;
}

/* Hide accessibility button */
arc-navbar #accessibility {
  display: none !important;
}

arc-sidebar {
  --gap-distance: 1rem;
  --arc-sidebar-width: 35%;
  --sidebar-width: clamp(20rem, 40%, var(--arc-sidebar-width));
}

#main {
  box-shadow: none;
}
